<template>
  <div>
    <quickstart>
      <orgaOverview v-if="$route.params.action === 'overview'" />
      <orgaSwitch v-else-if="$route.params.action === 'switch'" />
      <orgaNew v-else-if="$route.params.action === 'new'" />
      <orgaList v-else-if="$route.params.action === 'list'" />
      <orgaEdit v-else />
    </quickstart>
  </div>
</template>
<script>
export default {
  components: {
    orgaOverview () { return import('./overview/index.vue') },
    orgaSwitch () { return import('./switch/index.vue') },
    orgaList () { return import('./list/index.vue') },
    orgaNew () { return import('./list/new.vue') },
    orgaEdit () { return import('./edit/index.vue') },
    quickstart () { return import('./quickstart/index.vue') }
  },
  props: {},
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
</style>
